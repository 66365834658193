import { InAppReview } from "@capacitor-community/in-app-review";
import { isDefined } from "@clipboard-health/util-ts";
import { isPlatform } from "@ionic/core";
import { useWorkerPayroll } from "@src/appV2/Accounts/Payroll/api/useWorkerPayroll";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { addDays, isAfter, subDays } from "date-fns";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ALREADY_CHECKED_ELIGIBLE_SHIFTS_LOCAL_STORAGE_KEY =
  "in-app-rating-prompt-already-checked-eligible-shifts";
export const RATING_PROMPT_DATE_LOCAL_STORAGE_KEY = "in-app-rating-prompt-shown-date";

export function useShowInAppRatingPrompt() {
  const featureFlag = useCbhFlag(CbhFeatureFlag.IN_APP_RATING_PROMPT, {
    defaultValue: {
      isEnabled: false,
      promptCooldownPeriodInDays: 30,
      minimumEligibleShiftDurationInHours: 4,
      paidShiftCutoffDate: new Date().toISOString(),
      routesEnabledForPrompt: [],
    },
  });

  const location = useLocation();

  const { refetch: fetchWorkerPayroll } = useWorkerPayroll(
    {
      // longest time period to check that the backend supports is 31 days, anything longer than that will be invalid input
      startDate: subDays(new Date(), 31).toISOString(),
      endDate: new Date().toISOString(),
    },
    {
      // disabling to only fetch it if the criteria matches
      enabled: false,
      useErrorBoundary: false,
    }
  );

  const showPrompt = useCallback(() => {
    void InAppReview.requestReview();
    localStorage.setItem(RATING_PROMPT_DATE_LOCAL_STORAGE_KEY, new Date().toISOString());
    localStorage.setItem(ALREADY_CHECKED_ELIGIBLE_SHIFTS_LOCAL_STORAGE_KEY, JSON.stringify(true));
    logEvent(APP_V2_USER_EVENTS.IN_APP_RATING_PROMPT_TRIGGERED);
  }, []);

  const fetchPayrollAndShowPromptIfEligible = useCallback(async () => {
    logEvent(APP_V2_USER_EVENTS.IN_APP_RATING_PROMPT_FETCH_PAYROLL);
    const workerPayroll = await fetchWorkerPayroll();
    const hasAnEligiblePaidShift =
      workerPayroll.isSuccess &&
      workerPayroll.data.shifts.some(
        (shift) =>
          shift.paymentStatus.status === "Full Paid" &&
          shift.time >= featureFlag.minimumEligibleShiftDurationInHours &&
          // eslint-disable-next-line no-date-parsing/no-date-parsing
          isAfter(new Date(shift.end), new Date(featureFlag.paidShiftCutoffDate))
      );
    if (hasAnEligiblePaidShift) {
      showPrompt();
    }
  }, [
    featureFlag.minimumEligibleShiftDurationInHours,
    featureFlag.paidShiftCutoffDate,
    fetchWorkerPayroll,
    showPrompt,
  ]);

  useEffect(() => {
    if (!featureFlag.isEnabled) {
      return;
    }

    const isOnEnabledRoute = featureFlag.routesEnabledForPrompt.includes(location.pathname);
    if (!isOnEnabledRoute) {
      return;
    }

    const isOnCorrectPlatform =
      (isPlatform("ios") || isPlatform("android")) && !isPlatform("mobileweb");
    if (!isOnCorrectPlatform) {
      return;
    }

    const promptLastShownAt = localStorage.getItem(RATING_PROMPT_DATE_LOCAL_STORAGE_KEY);
    const hasBeenShownPromptTooRecently =
      isDefined(promptLastShownAt) &&
      isAfter(
        // eslint-disable-next-line no-date-parsing/no-date-parsing
        addDays(new Date(promptLastShownAt), featureFlag.promptCooldownPeriodInDays),
        new Date()
      );
    if (hasBeenShownPromptTooRecently) {
      return;
    }

    const alreadyHasAnEligiblePaidShift = JSON.parse(
      localStorage.getItem(ALREADY_CHECKED_ELIGIBLE_SHIFTS_LOCAL_STORAGE_KEY) ?? "false"
    ) as boolean;

    // we already know they have a paid shift, no need to fetch again
    if (alreadyHasAnEligiblePaidShift) {
      showPrompt();
      return;
    }

    void fetchPayrollAndShowPromptIfEligible();
  }, [featureFlag, fetchPayrollAndShowPromptIfEligible, location.pathname, showPrompt]);
}
