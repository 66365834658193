import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { z } from "zod";

const appConfigurationResponseSchema = z.object({
  disputedTimeDistance: z.number(),
  instantPay: z.object({
    defaultLunchTime: z.number(),
    gracePeriod: z.number().optional(),
    amountPercentage: z.number().optional(),
  }),
  facilitySafeDistanceMiles: z.number(),
  PushNotificationOnAccountTab: z.boolean(),
  minToAllowTimeCardUpload: z.number(),
  convertToNonIPAfterXMinutes: z.number(),
  minBeforeShiftStart: z.number(),
  shiftEndGracePeriod: z.number(),

  callOffWindow: z.number().optional(),
  cancellationHoursMin: z.number().optional(),
  cancellationHoursMax: z.number().optional(),
  notificationPreferences: z
    .object({
      sms: z.boolean(),
      push: z.boolean(),
      off: z.boolean(),
      long_code_sms: z.boolean(),
    })
    .optional(),
});

export type AppConfigurationResponse = z.infer<typeof appConfigurationResponseSchema>;

export function useAppConfiguration(options: UseGetQueryOptions<AppConfigurationResponse> = {}) {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/envVariables`,
    responseSchema: appConfigurationResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_APP_CONFIGURATION_FAILURE,
    },
    ...options,
  });
}
